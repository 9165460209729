import {
  HIDE_MESSAGE,
  SEARCH_MEMBERS,
  SEARCH_MEMBERS_SUCCESS,
  SEARCH_MEMBERS_FAILED,
  SEARCH_NON_MEMBERS,
  SEARCH_NON_MEMBERS_SUCCESS,
  SEARCH_NON_MEMBERS_FAILED,
  FILTER_SEARCH_MEMBERS,
  CLEAR_FILTER_SEARCH_MEMBERS,
  RESET_STATUS,
  VIEW_MEMBER_SUCCESS,
  VIEW_MEMBER_FAILED,
  VIEW_NON_MEMBER_SUCCESS,
  VIEW_NON_MEMBER_FAILED,
  CHANGE_STATUS_MEMBER_SUCCESS,
  CHANGE_STATUS_MEMBER_FAILED,
  UPLOAD_MEMBER,
  UPLOAD_MEMBER_FAILED,
  UPLOAD_MEMBER_SUCCESS,
  UPLOAD_TRANSACTION,
  UPLOAD_TRANSACTION_SUCCESS,
  UPLOAD_TRANSACTION_FAILED,
  NON_MEMBER_POINT_TRANSACTION,
  NON_MEMBER_POINT_TRANSACTION_SUCCESS,
  NON_MEMBER_POINT_TRANSACTION_FAILED,
  DOWNLOAD_NON_MEMBER_SUCCESS,
  DOWNLOAD_SUCCESS,
} from "constants/ActionTypes";

import {
  APPROVAL_MEMBER,
  APPROVAL_MEMBER_SUCCESS,
  APPROVAL_MEMBER_FAILED,
  MEMBER_DELINQUENCY,
  MEMBER_DELINQUENCY_SUCCESS,
  MEMBER_DELINQUENCY_FAILED,
  VIEW_MEMBER_DELINQUENCY,
  VIEW_MEMBER_DELINQUENCY_SUCCESS,
  VIEW_MEMBER_DELINQUENCY_FAILED,
  FILTER_SEARCH_MEMBERS_DELINQUENCY,
  GET_POINT_DETAIL,
  GET_POINT_DETAIL_SUCCESS,
  GET_POINT_DETAIL_FAILED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  listMembers: [],
  listNonMembers: [],
  listPointTransactions: [],
  successList: [],
  failedList: [],
  downloadData: [],
  recordInfo: {
    totalRecords: 0,
    page: 0,
    nrecords: 0,
  },
  filterAndSort: {
    pagination: null,
    filters: null,
    sorter: null,
    search: null,
    customFilter: null,
  },
  member: [
    {
      memberPicture: "",
      merchant: "",
      memberId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      placeOfBirth: "",
      birthDate: "",
      gender: "",
      tierName: "",
      referredBy: "",
      primaryAddress: "",
      addressLine1: "",
      country: "",
      province: "",
      cityTown: "",
      postalCode: "",
      mobileNumber: "",
      emailAddress: "",
      memberId: "",
      memberUsername: "",
      memberName: "",
      gender: "",
      mobileNumber: "",
      tier: "",
      merchant: "",
      merchantId: "",
      pointBalance: "",
      status: "",
      deliveryStatus: "",
      pointValue: "",
      accountNumber: "",
      additionalFields: "",
      arcMemberType: "",
    },
  ],
  memberArc: {
    merchantName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    accountNumber: "",
    birthDate: "",
    mobileNumber: "",
    emailAddress: "",
    ccNumber: "",
    deliquencyTagCc: "",
    premiumTagCc: "",
    onboardingChannelMigration: "",
  },
  requesList: {
    ruleType: "",
    // refNumber : '',
    totalTrxAmount: "",
    // productCode : '',
    memberUsername: "",
    ruleName: "",
    issuing: "",
  },
  updateSuccess: false,
  updateFailed: false,
  createSuccess: false,
  createFailed: false,
  deleteSuccess: false,
  deleteFailed: false,
  uploadSuccess: false,
  uploadFailed: false,
  uploadTrxSuccess: false,
  uploadTrxFailed: false,
  approvalMemberSuccess: false,
  approvalmemberFailed: false,
  memberListDelinquency: [], //
  memberDelinquencyDetail: [],
  filterAndSortDelinquency: {
    pagination: null,
    filters: null,
    sorter: null,
    search: null,
    customFilter: null,
  },
  pointDetail: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_MEMBERS: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listMembers: [],
        recordInfo: {},
      };
    }

    case SEARCH_NON_MEMBERS: {
      if (action.payload.isDownload) {
        return {
          ...state,
          loader: true,
          showMessage: false,
          alertMessage: "",
          downloadData: [],
        };
      }

      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listNonMembers: [],
        downloadData: [],
        recordInfo: {},
      };
    }

    case NON_MEMBER_POINT_TRANSACTION: {
      if (action.payload.isDownload) {
        return {
          ...state,
          loader: true,
          showMessage: false,
          alertMessage: "",
          downloadData: [],
        };
      }

      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listPointTransactions: [],
        recordInfo: {},
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    case FILTER_SEARCH_MEMBERS: {
      return {
        ...state,
        filterAndSort: action.payload,
      };
    }

    case FILTER_SEARCH_MEMBERS_DELINQUENCY: {
      return {
        ...state,
        filterAndSortDelinquency: action.payload,
      };
    }

    case CLEAR_FILTER_SEARCH_MEMBERS: {
      return {
        ...state,
        filterAndSort: {
          pagination: null,
          filters: null,
          sorter: null,
          search: null,
          searchUsername: null,
          searchMobileNumber: null,
          customFilter: null,
        },
        filterAndSortDelinquency: {
          pagination: null,
          filters: null,
          sorter: null,
          search: null,
          customFilter: null,
        },
      };
    }

    case RESET_STATUS: {
      return {
        ...state,
        updateSuccess: false,
        updateFailed: false,
        updateData: {
          memberId: "",
          memberName: "",
          memberType: "",
        },
        createSuccess: false,
        createFailed: false,
        createData: {
          memberId: "",
          memberName: "",
          memberType: "",
        },
        deleteSuccess: false,
        deleteFailed: false,
        uploadSuccess: false,
        uploadTrxSuccess: false,
        uploadFailed: false,
        uploadTrxFailed: false,
        approvalMemberSuccess: false,
        failedList: [],
        filterAndSort: {
          pagination: null,
          filters: null,
          sorter: null,
          search: null,
          customFilter: null,
        },
        filterAndSortDelinquency: {
          pagination: null,
          filters: null,
          sorter: null,
          search: null,
          customFilter: null,
        },
      };
    }

    case UPLOAD_MEMBER: {
      return {
        ...state,
        loader: true,
        uploadSuccess: false,
        uploadFailed: false,
      };
    }

    case UPLOAD_TRANSACTION: {
      return {
        ...state,
        loader: true,
        uploadTrxSuccess: false,
        uploadTrxFailed: false,
      };
    }

    case GET_POINT_DETAIL: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        pointDetail: [],
      };
    }

    // Response
    case SEARCH_MEMBERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        listMembers: action.payload.member,
        recordInfo: action.payload.recordInfo,
      };
    }

    case SEARCH_NON_MEMBERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        listNonMembers: action.payload.listNonMember,
        recordInfo: action.payload.recordInfo,
      };
    }

    case NON_MEMBER_POINT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        listPointTransactions: action.payload.result,
        recordInfo: action.payload.recordInfo,
      };
    }

    case SEARCH_MEMBERS_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case SEARCH_NON_MEMBERS_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case NON_MEMBER_POINT_TRANSACTION_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case VIEW_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        member: action.payload,
      };
    }

    case VIEW_NON_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        member: action.payload,
      };
    }

    case VIEW_MEMBER_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case VIEW_NON_MEMBER_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case CHANGE_STATUS_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        updateSuccess: true,
        updateFailed: false,
      };
    }

    case CHANGE_STATUS_MEMBER_FAILED: {
      return {
        ...state,
        loader: false,
        updateSuccess: false,
        updateFailed: true,
      };
    }

    case UPLOAD_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        uploadSuccess: true,
        uploadFailed: false,
      };
    }

    case UPLOAD_MEMBER_FAILED: {
      return {
        ...state,
        loader: false,
        uploadSuccess: false,
        uploadFailed: true,
        alertMessage: action.payload,
      };
    }

    case UPLOAD_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        uploadTrxSuccess: true,
        uploadTrxFailed: false,
        successList: action.payload.successList,
        failedList: action.payload.failedList,
      };
    }

    case UPLOAD_TRANSACTION_FAILED: {
      return {
        ...state,
        loader: false,
        uploadTrxSuccess: false,
        uploadTrxFailed: true,
        alertMessage: action.payload.abstractResponse.responseMessage,
        failedList: action.payload.failedList,
      };
    }

    case APPROVAL_MEMBER: {
      return {
        ...state,
        loader: false,
        approvalMemberSuccess: false,
        approvalmemberFailed: false,
      };
    }

    case APPROVAL_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        approvalMemberSuccess: true,
        approvalmemberFailed: false,
      };
    }

    case APPROVAL_MEMBER_FAILED: {
      return {
        ...state,
        loader: false,
        approvalMemberSuccess: false,
        approvalmemberFailed: true,
      };
    }

    case MEMBER_DELINQUENCY: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
      };
    }

    case DOWNLOAD_NON_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        downloadData: action.payload.listNonMember,
        recordInfo: action.payload.recordInfo,
      };
    }

    case DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loader: false,
        downloadData: action.payload.result,
        recordInfo: action.payload.recordInfo,
      };
    }

    case MEMBER_DELINQUENCY_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        alertMessage: "",
        memberListDelinquency: action.payload.memberListDelinquency,
        recordInfo: action.payload.recordInfo,
      };
    }

    case MEMBER_DELINQUENCY_FAILED: {
      return {
        ...state,
        loader: false,
        showMessage: true,
        alertMessage: action.payload,
        memberListDelinquency: [],
      };
    }

    case VIEW_MEMBER_DELINQUENCY: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
      };
    }

    case VIEW_MEMBER_DELINQUENCY_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        alertMessage: "",
        memberDelinquencyDetail: action.payload,
      };
    }

    case VIEW_MEMBER_DELINQUENCY_FAILED: {
      return {
        ...state,
        loader: false,
        showMessage: true,
        alertMessage: action.payload,
        memberDelinquencyDetail: [],
      };
    }

    case GET_POINT_DETAIL_SUCCESS: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        pointDetail: action.payload,
      };
    }

    case GET_POINT_DETAIL_FAILED: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        pointDetail: [],
      };
    }

    default:
      return state;
  }
};
