import Axios from "axios";

export const MembersSumaryApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  dataSummary,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/member/summary",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...dataSummary,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const MembersSumaryDailyApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  dataDaily,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/member/summary/daily",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...dataDaily,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const TotalMemberPerTierApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  dataTotalMemberPerTier,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/tier",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...dataTotalMemberPerTier,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const TotalCcCustomerApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  totalCcCustomer,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/member-cc/total",
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};
