import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SEARCH_ADVERTISING,
  SEARCH_ADVERTISING_SUCCESS,
  SEARCH_ADVERTISING_FAILED,
  VIEW_ADVERTISING,
  VIEW_ADVERTISING_SUCCESS,
  VIEW_ADVERTISING_FAILED,
  UPDATE_ADVERTISING,
  UPDATE_ADVERTISING_SUCCESS,
  UPDATE_ADVERTISING_FAILED,
  CREATE_ADVERTISING,
  CREATE_ADVERTISING_SUCCESS,
  CREATE_ADVERTISING_FAILED,
  DELETE_ADVERTISING,
  DELETE_ADVERTISING_SUCCESS,
  DELETE_ADVERTISING_FAILED,
  RESET_STATUS,
  BACK_TO_LOGIN,
  TOP_DEALS_ADVERTISING,
  TOP_DEALS_ADVERTISING_SUCCESS,
  TOP_DEALS_ADVERTISING_FAILED,
  SEARCH_ADVERTISING_ACTIVE,
  SEARCH_ADVERTISING_ACTIVE_SUCCESS,
  SEARCH_ADVERTISING_ACTIVE_FAILED,
  GET_TIER_AND_PROGRAM_SEARCH,
} from "constants/ActionTypes";
import {
  searchAdvertisingApi,
  viewAdvertisingApi,
  updateAdvertisingApi,
  createAdvertisingApi,
  deleteAdvertisingApi,
  topDealsAdvertisingApi,
  searchAdvertisingActiveApi,
} from "../../appRedux/api/Advertising";
import { fetchViewMerchant } from "./Merchant";
import { fetchSearchPrograms } from "./Program";

export function* fetchSearchAdvertising({ payload }) {
  if (payload != null) {
    try {
      const searchAdvertisingData = yield call(searchAdvertisingApi, payload);
      if (
        searchAdvertisingData.data.abstractResponse.responseStatus === "PSADV0"
      ) {
        yield put({
          type: SEARCH_ADVERTISING_SUCCESS,
          payload: searchAdvertisingData.data,
        });
      } else if (
        searchAdvertisingData.data.abstractResponse.responseStatus !==
        "ERROR016"
      ) {
        let resp = {
          advertising: [],
          recordInfo: {},
        };
        yield put({ type: SEARCH_ADVERTISING_SUCCESS, payload: resp });
      } else {
        yield put({
          type: SEARCH_ADVERTISING_FAILED,
          payload: searchAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: SEARCH_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: SEARCH_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchViewAdvertising({ payload }) {
  if (payload != null) {
    try {
      let viewAdvertisingData = yield call(viewAdvertisingApi, payload);
      if (
        viewAdvertisingData.data.abstractResponse.responseStatus === "PSADV0"
      ) {
        yield put({
          type: VIEW_ADVERTISING_SUCCESS,
          payload: viewAdvertisingData.data,
        });
      } else {
        yield put({
          type: VIEW_ADVERTISING_FAILED,
          payload: viewAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: VIEW_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: VIEW_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* postEditAdvertising({ payload }) {
  if (payload != null) {
    try {
      yield put({ type: RESET_STATUS });
      let editAdvertisingData = yield call(updateAdvertisingApi, payload);

      if (
        editAdvertisingData.data.abstractResponse &&
        editAdvertisingData.data.abstractResponse.responseStatus === "INQ004"
      ) {
        yield put({
          type: UPDATE_ADVERTISING_SUCCESS,
          payload: editAdvertisingData.data.data,
        });
      } else if (editAdvertisingData.data.code == "BE001") {
        yield put({
          type: UPDATE_ADVERTISING_FAILED,
          payload: editAdvertisingData.data.message,
        });
      } else {
        yield put({
          type: UPDATE_ADVERTISING_FAILED,
          payload: editAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 400) {
          yield put({
            type: UPDATE_ADVERTISING_FAILED,
            payload: error.response.data.errors[0].toString(),
          });
        } else if (error.response.status === 404) {
          yield put({
            type: UPDATE_ADVERTISING_FAILED,
            payload: "Bad Request Please Check your mandatory field",
          });
        } else if (
          error.response.data.abstractResponse.responseStatus === "AUTH001"
        ) {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: UPDATE_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: UPDATE_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* postInsertAdvertising({ payload }) {
  if (payload != null) {
    try {
      yield put({ type: RESET_STATUS });
      let insertAdvertisingData = yield call(createAdvertisingApi, payload);
      if (insertAdvertisingData.data.code === "BE001") {
        yield put({
          type: CREATE_ADVERTISING_FAILED,
          payload: insertAdvertisingData.data.message,
        });
      } else if (
        insertAdvertisingData.data.abstractResponse.responseStatus === "INQ003"
      ) {
        yield put({
          type: CREATE_ADVERTISING_SUCCESS,
          payload: insertAdvertisingData.data,
        });
      } else {
        yield put({
          type: CREATE_ADVERTISING_FAILED,
          payload: insertAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        yield put({
          type: CREATE_ADVERTISING_FAILED,
          payload: "Internal Server Error: " + error.response.data.message,
        })
      } else if (error.response && error.response.status === 400) {
        yield put({
          type: CREATE_ADVERTISING_FAILED,
          payload: error.response.data.responseMessage,
        })
      } else if (error.response !== undefined) {
        if (error.response.status === 400) {
          yield put({
            type: CREATE_ADVERTISING_FAILED,
            payload: error.response.data.errors[0].toString(),
          });
        } else if (error.response.status === 404) {
          yield put({
            type: CREATE_ADVERTISING_FAILED,
            payload: "Bad Request Please Check your mandatory field",
          });
        } else if (
          error.response.data.abstractResponse.responseStatus === "AUTH001"
        ) {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: CREATE_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } 
      } else {
        yield put({
          type: CREATE_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* postDeleteAdvertising({ payload }) {
  if (payload != null) {
    try {
      let deleteAdvertisingData = yield call(deleteAdvertisingApi, payload);

      if (
        deleteAdvertisingData.data.abstractResponse.responseStatus === "INQ005"
      ) {
        let responseData = deleteAdvertisingData.data.advertising;
        yield put({ type: DELETE_ADVERTISING_SUCCESS, payload: responseData });
      } else {
        yield put({
          type: DELETE_ADVERTISING_FAILED,
          payload: deleteAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: DELETE_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: DELETE_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* postTopDealsAdvertising({ payload }) {
  if (payload != null) {
    try {
      yield put({ type: RESET_STATUS });
      let topDelsAdvertisingData = yield call(topDealsAdvertisingApi, payload);
      if (
        topDelsAdvertisingData.data.abstractResponse.responseStatus === "INQ004"
      ) {
        yield put({
          type: TOP_DEALS_ADVERTISING_SUCCESS,
          payload: topDelsAdvertisingData.data.data,
        });
      } else {
        yield put({
          type: TOP_DEALS_ADVERTISING_FAILED,
          payload: topDelsAdvertisingData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: TOP_DEALS_ADVERTISING_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: TOP_DEALS_ADVERTISING_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

export function* fetchSearchAdvertisingActive({ payload }) {
  if (payload != null) {
    try {
      const searchAdvertisingActiveData = yield call(
        searchAdvertisingActiveApi,
        payload
      );
      if (
        searchAdvertisingActiveData.data.abstractResponse.responseStatus ===
        "INQ000"
      ) {
        yield put({
          type: SEARCH_ADVERTISING_ACTIVE_SUCCESS,
          payload: searchAdvertisingActiveData.data.advertisingPromotion,
        });
      } else {
        yield put({
          type: SEARCH_ADVERTISING_ACTIVE_FAILED,
          payload:
            searchAdvertisingActiveData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: SEARCH_ADVERTISING_ACTIVE_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: SEARCH_ADVERTISING_ACTIVE_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchAdvertisingProgramMerchant(param) {
  //Call synchronous api and put param
  yield call(fetchViewMerchant, param);
  yield call(fetchSearchPrograms, param);
}

export function* searchAdvertising() {
  yield takeEvery(SEARCH_ADVERTISING, fetchSearchAdvertising);
}

export function* viewAdvertising() {
  yield takeEvery(VIEW_ADVERTISING, fetchViewAdvertising);
}

export function* editAdvertising() {
  yield takeEvery(UPDATE_ADVERTISING, postEditAdvertising);
}

export function* createAdvertising() {
  yield takeEvery(CREATE_ADVERTISING, postInsertAdvertising);
}

export function* removeAdertising() {
  yield takeEvery(DELETE_ADVERTISING, postDeleteAdvertising);
}

export function* topDealsAdvertising() {
  yield takeEvery(TOP_DEALS_ADVERTISING, postTopDealsAdvertising);
}

export function* searchAdvertisingActive() {
  yield takeEvery(SEARCH_ADVERTISING_ACTIVE, fetchSearchAdvertisingActive);
}

export function* getTierAndProgram() {
  yield takeEvery(GET_TIER_AND_PROGRAM_SEARCH, fetchAdvertisingProgramMerchant);
}

// yield all
export default function* rootSaga() {
  yield all([
    fork(searchAdvertising),
    fork(viewAdvertising),
    fork(editAdvertising),
    fork(createAdvertising),
    fork(removeAdertising),
    fork(topDealsAdvertising),
    fork(searchAdvertisingActive),
    fork(getTierAndProgram),
  ]);
}
