import React from "react";
import ReactDOM from "react-dom";
// import LogRocket from 'logrocket';

// import NextApp from './NextApp';
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { AppContainer } from "react-hot-loader";

import CircularProgress from "components/CircularProgress/index";
// const NextApp = React.lazy(() => import('./NextApp'));
import NextApp from "./NextApp";

// Add this import:

//Environment
window.environment = "Development";
//BaseURL
// window.ApiURL = process.env.REACT_APP_BASE_API_URL || 'http://13.229.73.93:8080/lifestyle-ws-goodie/api-rest/';
window.ApiURL =
  process.env.REACT_APP_BASE_API_URL || "https://dev.goodie.id/api-rest/";
window.ApiURLNew =
  process.env.REACT_APP_BASE_API_URL_NEW ||
  "https://dev-goodie.devops.indivaragroup.com/";

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <React.Suspense
        fallback={
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        }
      >
        <NextApp />
      </React.Suspense>
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
// registerServiceWorker();
unregisterServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
