import {
  FILTER_SEARCH_REPORT,
  CLEAR_FILTER_SEARCH_REPORT,
  GET_ISSUING_REPORT,
  GET_REDEEM_REPORT,
  GET_MEMBER_BALANCE_REPORT,
  GET_NON_MEMBER_BALANCE_REPORT,
  GET_VOUCHER_BALANCE_REPORT,
  GET_REFERRAL_REPORT,
  GET_POINT_TRANSACTION_REPORT,
  GET_POINT_TRANSFER_REPORT,
  GET_PARTNER_REPORT,
  GET_POINTS_TRANSACTION_HISTORY_REPORT,
  GET_POINTS_VOUCHER_REDEMPTION_1_REPORT,
  GET_POINTS_VOUCHER_REDEMPTION_2_REPORT,
  FILTER_SEARCH_REPORT_CUSTOM,
  HIDE_MESSAGE,
  MEMBER_DELINQUENCY_REPORT,
  RESET_STATUS,
} from "constants/ActionTypes";
import {
  GET_OWN_VOUCHER_REPORT,
  GET_PRODUCTCC_REPORT,
} from "../../constants/ActionTypes";

export const filterSortSearch = (
  pagination,
  filters,
  sorter,
  search,
  startDate,
  endDate,
  customFilter
) => {
  let filter = {
    pagination: pagination,
    filters: filters,
    sorter: sorter,
    search: search,
    startDate: startDate,
    endDate: endDate,
    customFilter: customFilter,
  };
  return {
    type: FILTER_SEARCH_REPORT,
    payload: filter,
  };
};

export const filterSortSearchCustom = (
  pagination,
  filters,
  sorter,
  search,
  startDate,
  endDate
) => {
  let filter = {
    pagination: pagination,
    filters: filters,
    sorter: sorter,
    search: search,
    startDate: startDate,
    endDate: endDate,
  };
  return {
    type: FILTER_SEARCH_REPORT_CUSTOM,
    payload: filter,
  };
};

export const clearFilterSortSearch = () => {
  return {
    type: CLEAR_FILTER_SEARCH_REPORT,
  };
};

export const issuingReport = (request) => {
  return {
    type: GET_ISSUING_REPORT,
    payload: request,
  };
};

export const redeemReport = (request) => {
  return {
    type: GET_REDEEM_REPORT,
    payload: request,
  };
};

export const memberBalanceReport = (request) => {
  return {
    type: GET_MEMBER_BALANCE_REPORT,
    payload: request,
  };
};

export const nonMemberBalanceReport = (request) => {
  return {
    type: GET_NON_MEMBER_BALANCE_REPORT,
    payload: request,
  };
};

export const voucherBalanceReport = (request) => {
  return {
    type: GET_VOUCHER_BALANCE_REPORT,
    payload: request,
  };
};

export const ownVoucherReport = (request) => {
  return {
    type: GET_OWN_VOUCHER_REPORT,
    payload: request,
  };
};

export const referralReport = (request) => {
  return {
    type: GET_REFERRAL_REPORT,
    payload: request,
  };
};

export const pointTransactionReport = (request) => {
  return {
    type: GET_POINT_TRANSACTION_REPORT,
    payload: request,
  };
};

export const pointTransferReport = (request) => {
  return {
    type: GET_POINT_TRANSFER_REPORT,
    payload: request,
  };
};

export const partnerReport = (request) => {
  return {
    type: GET_PARTNER_REPORT,
    payload: request,
  };
};

export const pointsTransactionHistoryReport = (request) => {
  return {
    type: GET_POINTS_TRANSACTION_HISTORY_REPORT,
    payload: request,
  };
};

export const pointsVoucherRedemption1Report = (request) => {
  return {
    type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT,
    payload: request,
  };
};

export const pointsVoucherRedemption2Report = (request) => {
  return {
    type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT,
    payload: request,
  };
};

export const memberDelinquencyReport = (request) => {
  return {
    type: MEMBER_DELINQUENCY_REPORT,
    payload: request,
  };
};
export const productCCReport = (request) => {
  return {
    type: GET_PRODUCTCC_REPORT,
    payload: request,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const resetStatus = (request) => {
  return {
    type: RESET_STATUS,
  };
};
