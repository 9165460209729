import Axios from "axios";

export const PointAccuralApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  pointAccural,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/promotion",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...pointAccural,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const PointIssuingApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  dataPoints,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/merchant/availablepoint",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...dataPoints,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const PointRedeemedApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  dataPoints,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/merchant/deductpoint",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...dataPoints,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const TotalCcPointsApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  totalCcPoints,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/member-cc/totalPoint",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...totalCcPoints,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};
