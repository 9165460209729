import {
  POINT_ACCURAL,
  POINT_ACCURAL_SUCCESS,
  POINT_ACCURAL_FAILED,
  POINT_ISSUING,
  POINT_ISSUING_SUCCESS,
  POINT_ISSUING_FAILED,
  POINT_REDEEMED,
  POINT_REDEEMED_SUCCESS,
  POINT_REDEEMED_FAILED,
  TOTAL_CC_POINTS,
  TOTAL_CC_POINTS_SUCCESS,
  TOTAL_CC_POINTS_FAILED,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  listDataPointAccural: null,
  listDataPointIssuing: null,
  listDataPointRedeemed: null,
  listDataTotalCcPoints: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case POINT_ACCURAL: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataPointAccural: null,
      };
    }

    case POINT_ISSUING: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataPointAccural: null,
      };
    }

    case POINT_REDEEMED: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataPointAccural: null,
      };
    }

    case TOTAL_CC_POINTS: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataTotalCcPoints: [],
      };
    }

    // Response
    case POINT_ACCURAL_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataPointAccural: action.payload.promotionList,
      };
    }

    case POINT_ACCURAL_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case POINT_ISSUING_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataPointIssuing: action.payload,
      };
    }

    case POINT_ISSUING_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case POINT_REDEEMED_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataPointRedeemed: action.payload,
      };
    }

    case POINT_REDEEMED_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case TOTAL_CC_POINTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataTotalCcPoints: action.payload.totalPoint,
      };
    }

    case TOTAL_CC_POINTS_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    default:
      return state;
  }
};
