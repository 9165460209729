import Axios from "axios";

export const RedemeedPointsApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  redeemedPoints,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/reward/month",
      params: {
        memberId: userId,
        merchantId: merchantId,
        offset: redeemedPoints.offset,
        pageSize: redeemedPoints.pageSize,
        sortColumn: redeemedPoints.sortColumn ? redeemedPoints.sortColumn : 2,
        sortOption: 2,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const RedemeedRewardApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  redemeedReward,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/reward",
      params: {
        memberId: userId,
        merchantId: merchantId,
        offset: redemeedReward.offset,
        pageSize: redemeedReward.pageSize,
        sortColumn: redemeedReward.sortColumn ? redemeedReward.sortColumn : 2,
        sortOption: 2,
        ...redemeedReward,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const CustomerRedeemedApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  customerRedeemed,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/redeemed-point",
      params: {
        memberId: userId,
        merchantId: merchantId,
        offset: customerRedeemed.offset,
        pageSize: customerRedeemed.pageSize,
        ...customerRedeemed,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const PointsRedemtionApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  pointsRedemtion,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/member-cc/totalRedeemedPoint",
      params: {
        memberId: userId,
        merchantId: merchantId,
        ...pointsRedemtion,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};
