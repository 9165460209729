import {
  DASHBOARD_MEMBERS_SUMMARY,
  DASHBOARD_MEMBERS_SUMMARY_SUCCESS,
  DASHBOARD_MEMBERS_SUMMARY_FAILED,
  DASHBOARD_MEMBERS_SUMMARY_DAILY,
  DASHBOARD_MEMBERS_SUMMARY_DAILY_SUCCESS,
  DASHBOARD_MEMBERS_SUMMARY_DAILY_FAILED,
  TOTAL_MEMBER_PER_TIER,
  TOTAL_MEMBER_PER_TIER_SUCCESS,
  TOTAL_MEMBER_PER_TIER_FAILED,
  TOTAL_CC_CUSTOMERS,
  TOTAL_CC_CUSTOMERS_SUCCESS,
  TOTAL_CC_CUSTOMERS_FAILED,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  listDataMembers: [],
  listDataMembersDaily: null,
  listDataTotalMember: null,
  listDataTotalCcCustomer: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_MEMBERS_SUMMARY: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataMembers: [],
      };
    }

    case DASHBOARD_MEMBERS_SUMMARY_DAILY: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataMembersDaily: null,
      };
    }

    case TOTAL_MEMBER_PER_TIER: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataMembersDaily: null,
      };
    }

    case TOTAL_CC_CUSTOMERS: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: "",
        listDataTotalCcCustomer: [],
      };
    }

    // Response
    case DASHBOARD_MEMBERS_SUMMARY_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataMembers: action.payload,
      };
    }

    case DASHBOARD_MEMBERS_SUMMARY_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case DASHBOARD_MEMBERS_SUMMARY_DAILY_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataMembersDaily: action.payload,
      };
    }

    case DASHBOARD_MEMBERS_SUMMARY_DAILY_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case TOTAL_MEMBER_PER_TIER_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataTotalMember: action.payload,
      };
    }

    case TOTAL_MEMBER_PER_TIER_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    case TOTAL_CC_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        listDataTotalCcCustomer: action.payload.totalMember,
      };
    }

    case TOTAL_CC_CUSTOMERS_FAILED: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessage: true,
      };
    }

    default:
      return state;
  }
};
