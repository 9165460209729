import Axios from 'axios';

export const searchAuditTrailApi = (data) => {
    if(data.authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'audit-trail/records',
            params: {
                memberId : data.userId,
                merchantId : data.merchantId,
                orderBy : data.orderBy,
                orderType : data.orderType,
                nRecords : data.nRecords,
                page : data.page,
                keyword : data.keyword,
                moduleId : data.moduleId,
                typeId : data.typeId,
                startDate : data.startDate,
                endDate : data.endDate
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const viewAuditTrailApi = (data) => {
    if(data.authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'member/view',
            params: {
                userId : data.userId,
                merchantId : data.merchantId,
                memberId : data.id
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': data.authToken,
                'deviceUniqueId' : data.deviceId
            }
        });
    };
};

export const listModuleNameApi = ({authToken, deviceId, userId, merchantId}) => {
    if(authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'audit-trail/list/menu',
            params: {
                userId : userId,
                merchantId : merchantId,
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};