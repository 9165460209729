import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	BACK_TO_LOGIN,
	REDEEMED_POINTS,
	REDEEMED_POINTS_SUCCESS,
	REDEEMED_POINTS_FAILED,
	REDEEMED_REWARD,
	REDEEMED_REWARD_SUCCESS,
	REDEEMED_REWARD_FAILED,
	POINTS_REDEMPTION,
	POINTS_REDEMPTION_SUCCESS,
	POINTS_REDEMPTION_FAILED,
	CUSTOMERS_REDEEMED,
	CUSTOMERS_REDEEMED_SUCCESS,
	CUSTOMERS_REDEEMED_FAILED,
	DOWNLOAD_SUCCESS,
} from "../../../constants/ActionTypes";
import {
	RedemeedPointsApi,
	RedemeedRewardApi,
	PointsRedemtionApi,
	CustomerRedeemedApi,
} from "../../api/NewDashboard";

function* fetchRedemeedPoints({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(RedemeedPointsApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: REDEEMED_POINTS_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: REDEEMED_POINTS_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: REDEEMED_POINTS_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchRedemeedReward({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(RedemeedRewardApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: REDEEMED_REWARD_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: REDEEMED_REWARD_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: REDEEMED_REWARD_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchCustomerRedeemed({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(CustomerRedeemedApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: CUSTOMERS_REDEEMED_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: CUSTOMERS_REDEEMED_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: CUSTOMERS_REDEEMED_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchPointsRedemtion({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(PointsRedemtionApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: POINTS_REDEMPTION_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: POINTS_REDEMPTION_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: POINTS_REDEMPTION_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

export function* getRedemeedPoints() {
	yield takeEvery(REDEEMED_POINTS, fetchRedemeedPoints);
}

export function* getRedemeedReward() {
	yield takeEvery(REDEEMED_REWARD, fetchRedemeedReward);
}

export function* getCustomerRedeemed() {
	yield takeEvery(CUSTOMERS_REDEEMED, fetchCustomerRedeemed);
}

export function* getPointsRedemtion() {
	yield takeEvery(POINTS_REDEMPTION, fetchPointsRedemtion);
}

// yield all
export default function* depositSaga() {
	yield all([
		fork(getRedemeedPoints),
		fork(getRedemeedReward),
		fork(getCustomerRedeemed),
		fork(getPointsRedemtion),
	]);
}
