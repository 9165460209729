import Axios from "axios";

export const loyaltyPromotionApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  period,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: `${window.ApiURLNew}dashboard/v1/loyalty/promotion`,
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const loyaltyRewardApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  period,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: `${window.ApiURLNew}dashboard/v1/loyalty/reward`,
      params: {
        memberId: userId,
        merchantId: merchantId,
        period: period,
        offset: 0,
        pageSize: 15,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const loyaltyTotalApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: `${window.ApiURLNew}dashboard/v1/loyalty/total`,
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const marketplaceMerchantApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  period,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURL + "dashboard/marketplace/merchant",
      params: {
        memberId: userId,
        merchantId: merchantId,
        period: period,
        offset: 0,
        pageSize: 99999,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const marketplaceProductApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
  period,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURL + "dashboard/marketplace/product",
      params: {
        memberId: userId,
        merchantId: merchantId,
        period: period,
        offset: 0,
        pageSize: 99999,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const marketplaceRevenueApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURL + "dashboard/marketplace/revenue",
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const memberGrowthApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURL + "dashboard/member/growth",
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const memberSummaryApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: `${window.ApiURLNew}dashboard/v1/member/summary`,
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const pointAvaliableApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURL + "dashboard/ablepoint",
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const pointDeductApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: `${window.ApiURLNew}dashboard/v1/merchant/deductpoint`,
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const tierMemberApi = async ({
  authToken,
  deviceId,
  userId,
  merchantId,
}) => {
  if (authToken != null) {
    return await Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/v1/loyalty/tier",
      params: {
        memberId: userId,
        merchantId: merchantId,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};
