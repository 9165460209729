import Axios from "axios";

export const issuingReportApi = ({
  userId,
  merchantId,
  memberName,
  rmNumber,
  startDate,
  endDate,
  pageSize,
  page,
  authToken,
  deviceId,
  searchDateByCreation,
  merchantName,
}) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/issuing",
      params: {
        memberId: userId,
        merchantId: merchantId,
        memberName: memberName,
        rmNumber: rmNumber,
        startDate: startDate,
        endDate: endDate,
        offset: mulai,
        pageSize: pageSize,
        page: page,
        nRecords: pageSize,
        searchDateByCreation: searchDateByCreation,
        division_name: merchantName,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const redeemReportApi = ({
  userId,
  merchantId,
  memberName,
  rmNumber,
  startDate,
  endDate,
  page,
  pageSize,
  authToken,
  deviceId,
}) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/redeem",
      params: {
        memberId: userId,
        merchantId: merchantId,
        memberName: memberName,
        rmNumber: rmNumber,
        startDate: startDate,
        endDate: endDate,
        offset: mulai,
        pageSize: pageSize,
        page: page,
        nRecords: pageSize,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const memberBalanceApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/member-balance",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        rmNumber: data.rmNumber,
        startDate: data.startDate,
        endDate: data.endDate,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const nonMemberBalanceApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/non-member-balance",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        startDate: data.startDate,
        endDate: data.endDate,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const voucherBalanceApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/voucher-balance",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        rmNumber: data.rmNumber,
        productName: data.productName,
        status: data.status,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
        isOwnProduct: data.isOwnProduct,
        statusList: data.statusList,
        voucherId: data.voucherId,
        voucherCode: data.voucherCode,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const ownVoucherApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/own-voucher",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        status: data.status,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
        isOwnProduct: data.isOwnProduct,
        voucherName: data.voucherName,
        voucherPrice: data.voucherPrice,
        expiryStartDate: data.expiryStartDate,
        expiryEndDate:
          data.expiryEndDate === "Invalid date" ||
          data.expiryEndDate === null ||
          data.expiryEndDate === undefined
            ? ""
            : data.expiryEndDate,
        promoStartDate: data.promoStartDate,
        promoEndDate: data.promoEndDate,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const referralReportApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }

    // Buat objek params
    const params = {
      memberId: data.userId,
      merchantId: data.merchantId,
      memberName: data.memberName,
      startDate: data.startDate,
      endDate: data.endDate,
      offset: mulai,
      pageSize: data.pageSize,
      page: data.page,
      nRecords: data.pageSize,
    };

    // Tambahkan referralCode ke params jika ada
    if (data.referralCode && data.referralCode.trim() !== "") {
      params.referralCode = data.referralCode;
    }

    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/referral-member",
      params: params,
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const pointTransactionApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/point-transaction",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        trxType: data.trxType,
        memberName: data.memberName,
        rmNumber: data.rmNumber,
        startDate: data.startDate,
        endDate: data.endDate,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
        division_name: data.merchantName,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const pointTransferApi = (data) => {
  if (data.authToken != null) {
    // var mulai = 0;
    // var baris = 20;

    // if(page>0){
    //     mulai= pageSize + 1;
    //     baris=0;
    //     for(var i=0;i<=page;){
    //         baris = pageSize + baris;
    //         i++;
    //     }
    //     mulai = baris - 20 + 1;
    // }
    return Axios({
      method: "get",
      url: window.ApiURL + "point-transaction/point/transfer/history",
      params: {
        memberId: data.memberId,
        userId: data.userId,
        merchantId: data.merchantId,
        orderBy: "",
        orderType: 1,
        page: data.page,
        nRecords: data.pageSize,
        role: 1,
        startDate: data.startDate,
        endDate: data.endDate,
        username: data.username,
      },
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const reportPartnerApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/report-partner",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        productName: data.productName,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
        merchantIdPartner: data.merchantIdPartner,
      },
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};

export const pointsTransactionHistoryReportApi = ({
  userId,
  merchantId,
  accountNumber,
  startDate,
  endDate,
  page,
  pageSize,
  authToken,
  deviceId,
}) => {
  // export const pointsTransactionHistoryReportApi = (data) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/points-transaction-history",
      params: {
        memberId: userId,
        merchantId: merchantId,
        startDate: startDate,
        endDate: endDate,
        offset: mulai,
        pageSize: pageSize,
        page: page,
        nRecords: pageSize,
        rmNumber: accountNumber,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const pointsVoucherRedemption1ReportApi = ({
  userId,
  merchantId,
  accountNumber,
  startDate,
  endDate,
  page,
  pageSize,
  authToken,
  deviceId,
}) => {
  // export const pointsVoucherRedemption1ReportApi = (data) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/points-voucher-redemption-one",
      params: {
        memberId: userId,
        merchantId: merchantId,
        startDate: startDate,
        endDate: endDate,
        offset: mulai,
        pageSize: pageSize,
        page: page,
        nRecords: pageSize,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const pointsVoucherRedemption2ReportApi = ({
  userId,
  merchantId,
  accountNumber,
  startDate,
  endDate,
  page,
  pageSize,
  authToken,
  deviceId,
}) => {
  // export const pointsVoucherRedemption2ReportApi = (data) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURL + "report/points-voucher-redemption-two",
      params: {
        memberId: userId,
        merchantId: merchantId,
        startDate: startDate,
        endDate: endDate,
        offset: mulai,
        pageSize: pageSize,
        page: page,
        nRecords: pageSize,
        rmNumber: accountNumber,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const memberDelinquencyApi = ({
  userId,
  merchantId,
  page,
  pageSize,
  authToken,
  deviceId,
  startDate,
  endDate,
  startDateTaggedAsDelinquent,
  endDateTaggedAsDelinquent,
  vybeClientName,
  rmNumber,
}) => {
  if (authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (page > 0) {
      mulai = pageSize + 1;
      baris = 0;
      for (var i = 0; i <= page; ) {
        baris = pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "GET",
      url: window.ApiURLNew + "report/501/deliquency/getDeliquentReport",
      params: {
        vybeClientName: vybeClientName,
        rmNumber: rmNumber,
        startDate: startDate,
        endDate: endDate,
        startDateTaggedAsDelinquent: startDateTaggedAsDelinquent,
        endDateTaggedAsDelinquent: endDateTaggedAsDelinquent,
        merchantId: merchantId,
        userId: userId,
        page: page,
        nRecords: pageSize,
        pageSize: pageSize,
        offset: mulai,
      },
      data: {},
      headers: {
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const reportCCApi = (data) => {
  if (data.authToken != null) {
    var mulai = 0;
    var baris = 10;

    if (data.page > 0) {
      mulai = data.pageSize + 1;
      baris = 0;
      for (var i = 0; i <= data.page; ) {
        baris = data.pageSize + baris;
        i++;
      }
      mulai = baris - 10;
    }
    return Axios({
      method: "get",
      url: window.ApiURLNew + "report/product-cc",
      params: {
        memberId: data.userId,
        merchantId: data.merchantId,
        memberName: data.memberName,
        rewardName: data.rewardName,
        rmNumber: data.rmNumber,
        customerNumber: data.customerNumber,
        startDate: data.startDate,
        endDate: data.endDate,
        offset: mulai,
        pageSize: data.pageSize,
        page: data.page,
        nRecords: data.pageSize,
      },
      data: {},
      headers: {
        authToken: data.authToken,
        deviceUniqueId: data.deviceId,
      },
    });
  }
};
