import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    UPDATE_VALIDATION_VOUCHER,
    UPDATE_VALIDATION_VOUCHER_SUCCESS,
    UPDATE_VALIDATION_VOUCHER_FAILED,
    // RESET_STATUS,
    BACK_TO_LOGIN
} from "constants/ActionTypes";
import {
    updateValidationVoucherApi
} from "../../appRedux/api/ValidationVoucher";

function* postEditValidationVoucher({payload}) {
    if(payload != null){
        try {
            let editValidationVoucherData = yield call(updateValidationVoucherApi, payload);

            if (editValidationVoucherData.data.abstractResponse.responseStatus === 'INQ001') {
                yield put({type: UPDATE_VALIDATION_VOUCHER_SUCCESS, payload: editValidationVoucherData.data.abstractResponse.responseMessage});
            } else {
                yield put({type: UPDATE_VALIDATION_VOUCHER_FAILED, payload: editValidationVoucherData.data.abstractResponse.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: UPDATE_VALIDATION_VOUCHER_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }else{
                yield put({
                    type: UPDATE_VALIDATION_VOUCHER_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

export function* updateValidationVoucher(){
    yield takeEvery(UPDATE_VALIDATION_VOUCHER, postEditValidationVoucher);
}

// yield all
export default function* rootSaga() {
    yield all([
        fork(updateValidationVoucher),
    ]);
}