import Axios from "axios";

export const searchProductsApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  currencyType,
  search,
  isExternal,
  sortbyNumber,
  sortNumber,
  merchantIdProduct,
  ...restParams
}) => {
  if (authToken != null) {
    return Axios({
      method: "get",
      url: window.ApiURL + "redeem/product/search",
      params: {
        userId: userId,
        merchantId: merchantId,
        productName: search,
        sort: sortNumber,
        sortBy: sortbyNumber,
        // productCode : '',
        // productType : '',
        currencyId: currencyType,
        isExternal: isExternal,
        merchantIdProduct: merchantIdProduct,
        page: 0,
        nRecords: 1000,
        ...restParams,
      },
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const viewProductApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  id,
}) => {
  if (authToken != null) {
    return Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/redeem/product/view",
      params: {
        userId: userId,
        merchantId: merchantId,
        productId: id,
      },
      data: {},
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const updateProductApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  id,
  data,
}) => {
  if (authToken != null) {
    return Axios({
      method: "post",
      url: window.ApiURLNew + "dashboard/redeem/product/update",
      data: {
        userId: userId,
        merchantId: merchantId,
        productId: id,
        productCode: data.productCode,
        productName: data.productName,
        description: data.description,
        productType: data.productType,
        productImage: data.productImage,
        stock: data.stock,
        basePrice: data.basePrice,
        pointsEarned: data.pointsEarned,
        isAllMerchantExchangePoint: -1,
        detectAccountExchangePoint: 4,
        voucherValue: data.voucherValue,
        bpiProductTypeId: data.isBPIProducts ? data.bpiProductType : null,
        memberType: data.memberType,
        isMarketplace: data.isMarketplace,
      },
      headers: {
        "Content-Type": "application/json",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const createProductApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  data,
}) => {
  if (authToken != null) {
    return Axios({
      method: "post",
      url: window.ApiURLNew + "dashboard/redeem/product/create",
      data: {
        userId: userId,
        merchantId: merchantId,
        productId: data.productId,
        productCode: data.productCode,
        productName: data.productName,
        description: data.description,
        productType: data.productType,
        productImage: data.productImage,
        stock: data.stock,
        basePrice: data.basePrice,
        isMarketplace: data.isMarketplace,
        isAllMerchantExchangePoint: -1,
        pointsEarned: data.pointsEarned,
        detectAccountExchangePoint: 4,
        voucherValue: data.voucherValue,
        productDetails: data.productDetails,
        bpiProductTypeId: data.isBPIProducts ? data.bpiProductType : null,
        memberType: data.memberType,
        isMarketplace: data.isMarketplace,
        merchantIdExchangePoint: "",
      },
      headers: {
        "Content-Type": "application/json",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const deleteProductApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  id,
}) => {
  if (authToken != null) {
    return Axios({
      method: "post",
      url: window.ApiURL + "redeem/product/delete",
      data: {
        userId: userId,
        merchantId: merchantId,
        productId: id,
      },
      headers: {
        "Content-Type": "application/json",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const addStockApi = ({
  authToken,
  deviceId,
  userId,
  merchantId,
  data,
}) => {
  if (authToken != null) {
    return Axios({
      method: "post",
      url: window.ApiURL + "redeem/product/update/stock",
      data: {
        userId: userId,
        merchantId: merchantId,
        productId: data.productId,
        additionalStock: data.additionalStock,
        productDetails: data.productDetails,
      },
      headers: {
        "Content-Type": "application/json",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};

export const getProductTypeApi = ({ authToken, deviceId }) => {
  if (authToken != null) {
    return Axios({
      method: "get",
      url: window.ApiURLNew + "dashboard/redeem/get-product-type",
      params: {},
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authToken: authToken,
        deviceUniqueId: deviceId,
      },
    });
  }
};
